import mixpanel from 'mixpanel-browser'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public
  mixpanel.init(runtimeConfig.mixpanel, {
    cross_subdomain_cookie: true,
  })

  const isNotLocalHost =
    runtimeConfig.apiUrl === 'https://api.unrobe.com' ||
    'https://api.dev.unrobe.com'
  const actions = {
    identify: (id) => {
      if (isNotLocalHost) {
        mixpanel.identify(id)
      }
    },
    alias: (id) => {
      if (isNotLocalHost) {
        mixpanel.alias(id)
      }
    },
    track: (name, props) => {
      if (isNotLocalHost) {
        mixpanel.track(name, props)
      }
    },
    people: {
      set: (props) => {
        if (isNotLocalHost) {
          mixpanel.people.set(props)
        }
      },
    },
  }
  nuxtApp.provide('mixpanel', actions)
})
