import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    access: null,
    refresh: null,
    user: null,
    email: null,
    shownGuideModal: false,
    isLogin: false,
  }),

  actions: {
    async login({ password, email }) {
      let err
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData(`login`, () =>
        $fetch(`/auth/jwt/login/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            email,
            password,
          },
          onResponseError({ response }) {
            err = response._data.detail
          },
        }),
      )
      if (data.value) {
        this.access = data.value.access
        this.refresh = data.value.refresh
      }
      if (error.value) {
        console.log(error.value)
        clearNuxtData('login')
        return err
      }
    },
    async refreshToken() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('refresh', () =>
        $fetch(`/auth/jwt/refresh/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            refresh: this.refresh,
          },
        }),
      )
      if (data.value) {
        this.access = data.value.access
      }
      if (error.value) {
        this.access = null
        this.refresh = null
        console.log(error.value)
        return error.value
      }
    },
    async register({ password, email, cloudflareToken }) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('register', () =>
        $fetch(`/user/create_verification_email/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            email,
            password,
            cloudflare_token: cloudflareToken,
          },
        }),
      )
      if (data.value) {
        return data.value
      }
      if (error.value) {
        return error
      }
    },
    async getCurrentUser() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('user', () =>
        $fetch(`/user/get_current_user/`, {
          baseURL: apiUrl,
          method: 'GET',
        }),
      )
      if (data.value) {
        this.user = data.value
      }
      if (error.value) {
        console.log(error.value)
        clearNuxtData('user')
        return error.value
      }
    },
    async logUserOut() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('logout', () =>
        $fetch(`/user/logout/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            refresh: this.refresh,
          },
        }),
      )
      this.access = null
      this.refresh = null
      this.user = null
      if (error.value) {
        console.log(error.value)
        clearNuxtData('logout')
      }
    },
    async deleteAccount() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('userDelete', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'DELETE',
        }),
      )
      if (error.value) {
        console.log(error.value)
        clearNuxtData('userDelete')
      }
    },
    async cancelSubscription() {
      const apiUrl = useRuntimeConfig().public.apiUrl

      const { data, error } = await useAsyncData('cancelSub', () =>
        $fetch(`/payment/cancel_membership/`, {
          baseURL: apiUrl,
          method: 'POST',
        }),
      )

      if (data.value) {
        const statusCode = response.status
        console.log('Success! Status Code:', statusCode)
        return statusCode
      }

      if (error.value) {
        console.log(error.value)
        // clearNuxtData('cancelSub')

        const errorCode = error.value.statusCode

        return errorCode
      }
    },

    async updateUser({ username, plan, safeMode }) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('userUpdate', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'PUT',
          body: {
            username: username,
            plan: plan,
            safeMode: safeMode,
          },
        }),
      )
      if (data.value) {
      }
      if (error.value) {
        console.log(error.value)
        clearNuxtData('userUpdate')
      }
    },
    async updateUsername(username) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      let err
      const { data, error } = await useAsyncData('usernameUpdate', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'PUT',
          body: {
            username: username,
          },
          onResponseError({ response }) {
            err = response._data.username
          },
        }),
      )
      if (error) {
        return err
      }
    },
    async updatePassword({ oldPassword, newPassword, confirmNewPassword }) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      let err
      const { data, error } = await useAsyncData('usernameUpdate', () =>
        $fetch(`/user/change_password/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            old_password: oldPassword,
            password: newPassword,
            confirm_password: confirmNewPassword,
          },
          onResponseError({ response }) {
            err = response._data.password
          },
        }),
      )
      if (error) {
        return err
      }
    },
    // async takeDataByGoogleToken(googleToken) {
    //   const apiUrl = useRuntimeConfig().public.apiUrl
    //   const { data, error } = await useAsyncData('googleToken', () =>
    //     $fetch(`/user/oauth/`, {
    //       baseURL: apiUrl,
    //       method: 'POST',
    //       body: {
    //         token: googleToken,
    //       },
    //     }),
    //   )
    //   if (data.value) {
    //     this.access = data?.value?.access_token
    //     this.refresh = data?.value?.refresh_token
    //   }
    //   if (error.value) {
    //     console.log(error.value)
    //     clearNuxtData('googleToken')
    //   }
    // },
    setRegistEmail(value) {
      this.email = value
    },
    deleteEmail() {
      this.email = ''
    },
    changeIsLogin(value) {
      this.isLogin = value
    },
    setTokens(value) {
      this.access = value.access
      this.refresh = value.refresh
    },
    changeGuideModal(value) {
      this.shownGuideModal = value
    },
    removesTokens() {
      this.access = null
      this.refresh = null
    },
  },
  getters: {
    getAccessToken: (state) => state.access,
    getRefreshToken: (state) => state.refresh,
    getUser: (state) => state.user,
    getEmail: (state) => state.email,
    getGuideModal: (state) => state.shownGuideModal,
    getIsLogin: (state) => state.isLogin,
  },
  persist: [
    {
      paths: ['access', 'refresh', 'user.credits', 'user.subscription_plan'],
      storage: persistedState?.localStorage,
    },
  ],
})
