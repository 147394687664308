import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as _91name_93QtbZDloVnTMeta } from "/app/pages/blog/[name].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as clothes_45removerPTIhNO0OPKMeta } from "/app/pages/clothes-remover.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as create_1j83DAPT6HvMeta } from "/app/pages/create_1.vue?macro=true";
import { default as createyuywxUUkQlMeta } from "/app/pages/create.vue?macro=true";
import { default as deepnudegKR5mGH7byMeta } from "/app/pages/deepnude.vue?macro=true";
import { default as editm3OgXtuFe4Meta } from "/app/pages/edit.vue?macro=true";
import { default as exploreRKECn8OWt4Meta } from "/app/pages/explore.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexYgRHYxGzjsMeta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as newLjAJoh5oRtMeta } from "/app/pages/password-reset/new.vue?macro=true";
import { default as policyhDmBrXN4qOMeta } from "/app/pages/policy.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as registrationre4qBsMVLdMeta } from "/app/pages/registration.vue?macro=true";
import { default as signinUhHmpbjN2pMeta } from "/app/pages/signin.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as success0bZk2aWKHZMeta } from "/app/pages/success.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountIWnKuvcAuMMeta || {},
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "SinglBlock",
    path: "/blog/:name()",
    meta: _91name_93QtbZDloVnTMeta || {},
    component: () => import("/app/pages/blog/[name].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "clothes-remover",
    path: "/clothes-remover",
    meta: clothes_45removerPTIhNO0OPKMeta || {},
    component: () => import("/app/pages/clothes-remover.vue").then(m => m.default || m)
  },
  {
    name: "Contact",
    path: "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "create_1",
    path: "/create_1",
    component: () => import("/app/pages/create_1.vue").then(m => m.default || m)
  },
  {
    name: "create",
    path: "/create",
    meta: createyuywxUUkQlMeta || {},
    component: () => import("/app/pages/create.vue").then(m => m.default || m)
  },
  {
    name: "deepnude",
    path: "/deepnude",
    meta: deepnudegKR5mGH7byMeta || {},
    component: () => import("/app/pages/deepnude.vue").then(m => m.default || m)
  },
  {
    name: "Edit",
    path: "/edit",
    meta: editm3OgXtuFe4Meta || {},
    component: () => import("/app/pages/edit.vue").then(m => m.default || m)
  },
  {
    name: "Explore",
    path: "/explore",
    meta: exploreRKECn8OWt4Meta || {},
    component: () => import("/app/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-new",
    path: "/password-reset/new",
    component: () => import("/app/pages/password-reset/new.vue").then(m => m.default || m)
  },
  {
    name: "Policy",
    path: "/policy",
    meta: policyhDmBrXN4qOMeta || {},
    component: () => import("/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "Success",
    path: "/success",
    meta: success0bZk2aWKHZMeta || {},
    component: () => import("/app/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "Terms",
    path: "/terms",
    meta: termsy18ZESLqu6Meta || {},
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "Verify",
    path: "/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  }
]